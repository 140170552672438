<template>
  <div>
    <b-form-group
      :label-cols-sm="hideLabel ? null : '12'"
      :label-cols-lg="hideLabel ? null : labelCols || '3'"
      :label="hideLabel ? null : 'เลือกผู้ช่วยเอเยนต์'"
      label-for="userAgent"
    >
      <b-select id="userAgent" v-model="userAgentId" :disabled="readonly">
        <b-form-select-option value="">
          {{ hasSelectAll ? 'เลือกทุกผู้ช่วยเอเยนต์' : 'เลือกผู้ช่วยเอเยนต์' }}
        </b-form-select-option>
        <b-form-select-option
          v-for="(userAgent, userAgentI) in userAgentList"
          :key="userAgentI"
          :value="userAgent.id"
          :disabled="disabled"
        >
          {{ userAgent.name }} {{ userAgent.isBanned ? '(ถูกระงับ)' : '' }}
        </b-form-select-option>
      </b-select>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserAgentSelectInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    labelCols: {
      type: String,
      default: '3',
    },
    hasSelectAll: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    masterId: {
      type: String,
      default: '',
    },
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userAgentId: '',
    }
  },
  computed: {
    ...mapGetters(['isOwner', 'isMaster', 'userInfo', 'assistantsForInput']),
    userAgentList() {
      const assistants = this.assistantsForInput
      const filteredAssistants = assistants.filter(
        (v) => v.agentId == this.agentId
      )
      return filteredAssistants
    },
  },
  watch: {
    masterId() {
      this.userAgentId = ''
    },
    agentId() {
      this.userAgentId = ''
    },
    userAgentId(val) {
      this.$emit('update', val)
    },
    value(val) {
      if (val && !this.userAgentId) {
        this.userAgentId = val
      }
    },
  },
  created() {
    if (this.value) {
      this.userAgentId = this.value
    }
  },
  methods: {
    ...mapActions(['fetchAssistants']),
  },
}
</script>
